var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-pages my-5 pt-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5"},[_c('div',{staticClass:"card overflow-hidden"},[_vm._m(0),_c('div',{staticClass:"card-body pt-0"},[_vm._m(1),_c('b-overlay',{attrs:{"show":_vm.is_loading}},[_c('div',{staticClass:"p-2"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.tryToResetpwd.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"useremail"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{ 'is-invalid': _vm.submitted && _vm.$v.email.$error },attrs:{"type":"email","id":"useremail","placeholder":"Enter email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.submitted && !_vm.$v.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"pwd"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && _vm.$v.password.$error,
                      },attrs:{"type":"password","id":"pwd","placeholder":"Enter password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(_vm.submitted && !_vm.$v.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"confirm_pwd"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.password_confirmation.$error,
                      },attrs:{"type":"password","id":"confirm_pwd","placeholder":"Enter confirm password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.password_confirmation=$event.target.value}}}),(_vm.submitted && !_vm.$v.password_confirmation.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group row mb-0"},[_c('div',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-primary w-md",attrs:{"type":"submit"}},[_vm._v(" Reset ")])])])])])])],1)]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" Remember It ? "),_c('router-link',{staticClass:"font-weight-medium text-primary",attrs:{"tag":"a","to":"/auth/login-1"}},[_vm._v("Sign In here")])],1),_c('p',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Stratosfia ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-soft bg-primary"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"text-primary p-4"},[_c('h5',{staticClass:"text-primary"},[_vm._v("Reset Password")]),_c('p',[_vm._v("Create a new password")])])]),_c('div',{staticClass:"col-5 align-self-end"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/profile-img.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"href":"/"}},[_c('div',{staticClass:"avatar-md profile-user-wid mb-4"},[_c('span',{staticClass:"avatar-title rounded-circle bg-light"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":require("@/assets/images/logo.svg"),"alt":"","height":"34"}})])])])])
}]

export { render, staticRenderFns }